<template>
  <div>
    <v-card
      :class="description ? 'conection-card' : ''"
      elevation="6"
      :style="
        $vuetify.breakpoint.lgAndDown ? '' : 'padding: 0px 50px 0px 50px;'
      "
    >
      <div style="text-align: center; padding: 10% 0">
        <v-col>
          <img
            v-if="icon"
            :src="icon"
            :style="$vuetify.breakpoint.xs ? 'height: 50px' : 'height: 80px'"
          />
          <v-icon
            v-else-if="vicon"
            v-text="vicon"
            color="primary"
            x-large
          ></v-icon>
        </v-col>
        <v-card-title class="pa-0">
          <v-col :class="'px-0 pl-md-6'">{{ title }}</v-col>
        </v-card-title>
        <v-card-text
          style="
            text-align: center;
            color: var(--v-gris1-base);
            font-size: 12px;
            padding: 0px 30px 0px 30px;
          "
        >
          {{ description }}
          <p v-if="error" v-text="error" class="error--text" />
        </v-card-text>
      </div>
    </v-card>
    <v-card-actions
      class="px-0"
      :style="
        $vuetify.breakpoint.lgAndDown
          ? 'margin-bottom: 20px'
          : 'margin-bottom: 255px'
      "
    >
      <slot v-if="$vuetify.breakpoint.smAndDown"></slot>
      <v-row justify="center" v-else>
        <slot></slot>
      </v-row>
    </v-card-actions>
  </div>
</template>
<script>
export default {
  name: "ConectionCard",
  props: ["title", "description", "icon", "error", "vicon"],
};
</script>

<style scoped lang="sass">
.conection-card
  height: 300px
  .connection-btn
    position: absolute
    bottom: 0
    right: 5px
  .conection-icon
    display: block
    text-align: center
</style>
